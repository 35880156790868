<template>
  <div>
    <div class="my-8 w-full sm:w-1/3">
      <a-input
        v-model="query"
        type="search"
        placeholder="Find Virtual Booth"
        class="h-12"
      />
    </div>
    <div v-if="sponsors.length>0">
      <h1
        class="text-3xl uppercase font-bold text-black mb-6"
      >
        Presenting Sponsor
      </h1>
      <div class="space-y-6 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8 mb-16 mt-4">
        <sponsor-card
          v-for="sponsor in sponsors"
          :key="sponsor.id"
          :exhibitor="sponsor"
        />
      </div>
    </div>

    <div v-if="rows.length>0">
      <h2
        class="text-3xl uppercase font-bold text-black mb-6"
      >
        Exhibitors
      </h2>
      <div
        class="grid gap-2 grid-cols-2 sm:gap-6 lg:grid-cols-4 lg:gap-6 xl:grid-cols-6"
      >
        <exhibitors-card
          v-for="exhibitor in rows"
          :key="exhibitor.id"
          :exhibitor="exhibitor"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SponsorCard from '@/components/exhibitors/cards/featured.vue';
import ExhibitorsCard from '@/components/sponsor/cards/exhibitor.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty, includes, filter } from '@/utils/lodash';
export default {
  components: {
    SponsorCard,
    ExhibitorsCard
  },
  data: () => ({
    query: ''
  }),
  computed: {
    ...mapState('exhibitors', ['exhibitors']),
    ...mapGetters('conferences', ['current']),
    sponsors() {
      let rows = filter(
        this.exhibitors,
        row => row.is_sponsor && !isEmpty(row.card_logo)
      );

      if (!isEmpty(this.query)) {
        rows = filter(rows, row =>
          includes(row.title.toLowerCase(), this.query.toLowerCase())
        );
      }

      return rows;
    },
    rows() {
      let rows = filter(
        this.exhibitors,
        row => !row.is_sponsor && !isEmpty(row.card_logo)
      );

      if (!isEmpty(this.query)) {
        rows = filter(rows, row =>
          includes(row.title.toLowerCase(), this.query.toLowerCase())
        );
      }

      return rows;
    }
  },
  async created() {
    await this.all(this.current);
  },
  methods: {
    ...mapActions('exhibitors', ['all'])
  }
};
</script>