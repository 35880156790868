<template>
  <booth
    v-if="loaded"
    :exhibitor="exhibitor"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import Booth from '@/components/booth/booth.vue';
export default {
  components: {
    Booth
  },
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters('exhibitors/exhibitor', ['loaded', 'exhibitor'])
  }
};
</script>