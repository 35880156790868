<template>
  <router-view :key="$route.path" />
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters('conferences', ['current']),
    ...mapGetters('auth', ['isUser', 'canViewExhibitors'])
  },
  watch: {
    isUser: {
      immediate: true,
      handler: 'onUserChange'
    }
  },
  methods: {
    onUserChange() {
      if (this.isUser && !this.canViewExhibitors) {
        this.$router.replace({
          name: 'dashboard',
          params: { conference: this.current }
        });
      }
    }
  }
};
</script>