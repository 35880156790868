<template>
  <router-view :key="$route.path" />
</template>

<script>
import { mapActions } from 'vuex';
export default {
  props: {
    slug: {
      type: String,
      required: true
    }
  },
  watch: {
    slug: {
      immediate: true,
      handler: 'onSlugChange'
    }
  },
  beforeUnmount() {
    this.clear();
  },
  methods: {
    ...mapActions('exhibitors/exhibitor', ['get', 'clear']),
    async onSlugChange() {
      const data = await this.get(this.slug);
      console.log(data);
    }
  }
};
</script>