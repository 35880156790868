<template>
  <div :class="['sponsor', {'sponsor--dark': dark}]">
    <router-link :to="{name: 'virtual.exhibitors.view', params: {slug: exhibitor.slug}}">
      <div class="aspect-w-3 aspect-h-2">
        <img
          :src="logo"
          :alt="alt"
          :class="['image', {'image--dark': dark}]"
        >
      </div>
    </router-link>
  </div>
</template>

<script>
import { get } from "@/utils/lodash";
export default {
  props: {
    exhibitor: {
      type: Object,
      required: true
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    alt() {
      return this.exhibitor.title;
    },
    logo() {
      return get(this.exhibitor, 'card_logo.url');
    }
  }
};
</script>

<style lang="scss" scoped>
.sponsor {
  @apply bg-white rounded-lg;

  &--dark {
    @apply bg-black;
  }
}

.image {
  @apply object-contain rounded-lg p-2 shadow-md hover:shadow-lg overflow-hidden border border-gray-200;

  &--dark {
    @apply border-black;
  }
}
</style>